.boxSmall {
  @apply mb-4 md:mb-0;
  width: 222;
  height: 193;
}

.boxLarge {
  @apply mb-4 md:mb-0;
  width: 222;
  height: 406;
}

.boxFeatured {
  @apply pt-4 md:pt-0;
}

.loaderWrapper {
  @apply flex w-full h-full items-center justify-center h-40 w-40;
}