@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-width: 100vw;
  min-height: 100vh;
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button-bevel;
}

h1 {
  font-weight: 700;
  margin: 0;
}

h2 {
  font-weight: 700;
  margin: 0;
}

h4 {
  margin: 0;
}

#root {
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.Toastify__toast-body {
  margin-left: 5px;
}

.Toastify__progress-bar {
  height: 3px;
}

.Toastify__close-button {
  color: black;
}

.Toastify__toast {
  background: white;
  color: #2b2b2b;
}

.Toastify__toast::after {
  width: 5px;
  height: 51px;
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
  border-radius: 5px;
  overflow: hidden;
}

.Toastify__toast--success .Toastify__progress-bar {
    background-color: #6FCF97;
}

.Toastify__toast--success::after {
    background-color: #6FCF97;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #EB5757;
}

.Toastify__toast--error::after {
  background-color: #EB5757;
}

.Toastify__toast--warning .Toastify__progress-bar {
  background-color: #F7A001;
}

.Toastify__toast--warning::after {
  background-color: #F7A001;
}

.Toastify__toast--info .Toastify__progress-bar {
  background-color: #085296;
}

.Toastify__toast--info::after {
  background-color: #085296;
}

.information-tooltip {
  max-width: fit-content;
}

.information-tooltip .ant-tooltip-inner {
  background-color: transparent;
  padding: 0;
  min-width: fit-content;
  min-height: fit-content;
}

.DateRangePicker_picker__portal {
  z-index: 9999;
}

.dialog {
  z-index: 3000 !important;
}

.movement-mode {
  cursor: grab;
}

.perspective-tool-active {
  cursor: zoom-in;
}

.moving {
  cursor: move;
}

.dragging {
  cursor: grabbing;
}

.cursor-pointer,
.btn {
  cursor: pointer;
}

canvas:focus-visible {
  outline: none;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 12px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}