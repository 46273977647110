.orthophotoPreview {
  @apply w-full h-fit md:h-full relative bg-gray-50 rounded-md flex items-center justify-center;
}

.orthophoto-wrapper {
  @apply w-full h-full flex items-center justify-center p-4 md:p-8 lg:p-8;
  max-height: 100%;
}

.ortho-img {
  @apply w-full h-full;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
}