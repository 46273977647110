.dialogRow {
  @apply p-4;
  width: 100%;
  max-width: calc(100vw - 30px);
}

.dialog-content {
  @apply h-full pt-4;
}

.dialog-header-title {
  margin-bottom: 0 !important;
}

.dialog-footer {
  @apply flex justify-start items-center pt-2 pb-8;
}

.dialog-footer .btn {
  min-width: 140px;
}

.close-btn-wrapper {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  display: flex;
  width: 24px;
  height: 24px;
  right: 1rem;
  top: 1rem;
}