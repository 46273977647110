.subscription-info-row {
  @apply flex flex-col md:flex-row items-center justify-between rounded-md p-4 w-full border border-gray-200;
}

.subscription-info-row-primary {
  @apply bg-primary-100;
}

.subscription-info-row-danger {
  @apply bg-red-100;
}

.subscription-info-row-blue {
  @apply bg-blue-100;
}

.subscription-info-row-transparent {
  @apply bg-transparent;
}

.icon-wrapper {
  @apply mr-4 flex items-center justify-center w-12 h-12 rounded-full mb-2 md:mb-0;
  min-width: 48px;
}

.icon-wrapper-primary {
  @apply bg-primary-200;
}

.icon-wrapper-blue {
  @apply bg-blue-200;
}

.icon-wrapper-danger {
  @apply bg-red-200;
}

.content-wrapper {
  @apply flex flex-col md:flex-row items-center justify-center w-full md:justify-start;
}

.primary-text {
  @apply font-semibold text-base text-black mb-2 md:mb-0;
}

.primary-text-danger {
  @apply text-red-600;
}

.secondary-text {
  @apply text-sm text-gray-500 mb-2 md:mb-0;
  white-space: pre-line;
}

.texts-wrapper {
  @apply max-w-4xl;
}

.button-wrapper {
  @apply w-full justify-start flex md:block md:w-fit;
}