.badge {
  @apply inline-flex items-center px-2 justify-start flex-row text-xs  rounded-lg;
  height: 16px;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1; /* 15.4px */
}

.badge.danger {
  @apply text-red-500 border border-red-500 bg-red-100;
}

.badge.success {
  @apply text-green-500 border border-green-500 bg-green-100;
}

.badge.primary {
  @apply text-primary-500 border border-primary-500 bg-primary-100;
}

.badge.grey {
  @apply text-black border border-black bg-gray-100;
}