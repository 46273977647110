.featured-wrapper {
  @apply absolute bg-primary-500 rounded-md text-white text-center font-semibold text-sm;
  left: -2px;
  bottom: -2px;
  height: calc(100% + 26px);
  width: calc(100% + 4px);
  box-shadow: 0px 0px 40px rgba(88, 18, 247, 0.13);
}

.featured-label {
  @apply flex items-center justify-center;
  height: 22px;
}